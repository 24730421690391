import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import DynamicImage from "../dynamic-image"

// const StyledLink = styled(props => <Link {...props} />)`
//   color: inherit;
//   text-decoration: none;
// `

// const RANDOM_IMG = "https://source.unsplash.com/collection/175083/640x360"

const Container = styled(props => <Link {...props} />)`
  height: 369px;
  width: 328px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  /* @media screen and (max-width: 1079px) {
    width: 504px;
  }
  @media screen and (max-width: 599px) {
    width: 328px;
  } */
`

const ContainerImage = styled.div`
  width: 100%;
  height: 219px;
  display: flex;
  justify-content: center;
`

const ContainerText = styled.div`
  padding: 16px 20px 16px 20px;
  width: 100%;
  height: 150px;
`

const PublishedDate = styled.p`
  height: 18px;
  color: #222222;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  margin-bottom: 4px;
`

const Title = styled.p`
  color: #222222;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
  margin-bottom: 4px;
`

const TagButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.28px;
  margin: 0 4px 0 0;
  /* padding-bottom: 1px; */
  text-align: center;
  box-sizing: border-box;
  height: 24px;
  width: fit-content;
  border: 1px solid #dfdfdf;
  border-radius: 12px;
  background-color: #efefef;
  /* box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15); */
`

const RandomImage = styled.img`
  width: 100%;
  height: 219px;
  object-fit: cover;
  margin-bottom: 0px;
`

const TopicsLink = ({ data }) => {
  const title = data.title
  const tags = data.category
  const createdDate = data.createdAt
  const slug = data.slug

  if (tags && data.topimage) {
    const tagName = tags?.name && tags.name
    const photo = data.topimage.gatsbyImageData
    const showTopicCard = (
      <TopicCard
        slug={slug}
        photo={photo}
        createdDate={createdDate}
        title={title}
        tagName={tagName}
      />
    )
    return showTopicCard
  }
  if (tags && !data.topimage) {
    const tagName = tags?.name && tags.name
    const photo = tags.image.gatsbyImageData
    const showTopicCard = (
      <TopicCard
        slug={slug}
        photo={photo}
        createdDate={createdDate}
        title={title}
        tagName={tagName}
      />
    )
    return showTopicCard
  }

  if (!tags && data.topimage) {
    const photo = data.topimage.gatsbyImageData
    const showTopicCard = (
      <TopicCardNoTags
        slug={slug}
        photo={photo}
        createdDate={createdDate}
        title={title}
      />
    )
    return showTopicCard
  }

  if (!tags && !data.topimage) {
    const photo = "https://source.unsplash.com/collection/175083/640x360"
    const showTopicCard = (
      <TopicCardNoTags
        slug={slug}
        photo={photo}
        createdDate={createdDate}
        title={title}
      />
    )
    return showTopicCard
  }
}

const TopicCard = ({ slug, photo, createdDate, title, key, tagName }) => {
  return (
    <Container to={`/topics/${slug}/`}>
      <ContainerImage>
        <DynamicImage image={photo} />
      </ContainerImage>
      <ContainerText>
        <PublishedDate>{createdDate}</PublishedDate>
        <Title>{title}</Title>
        <TagButton>{tagName}</TagButton>
      </ContainerText>
    </Container>
  )
}

const TopicCardNoTags = ({ slug, photo, createdDate, title }) => {
  return (
    <Container to={`/topics/${slug}`}>
      <ContainerImage>
        <RandomImage src={photo} alt="post-cover" />
      </ContainerImage>
      <ContainerText>
        <PublishedDate>{createdDate}</PublishedDate>
        <Title>{title}</Title>
      </ContainerText>
    </Container>
  )
}

export default TopicsLink
