import React from "react"
// import { Link } from "gatsby"
import styled from "@emotion/styled"
import { StaticImage } from "gatsby-plugin-image"
// import Icon1 from "../../images/svg/icon-industry-1.svg"
import Icon2 from "../../images/svg/icon-industry-2.svg"
import Icon3 from "../../images/svg/icon-industry-3.svg"
import Icon4 from "../../images/svg/icon-purpose-4.svg"
import Icon5 from "../../images/svg/icon-purpose-5.svg"
import Icon6 from "../../images/svg/icon-purpose-6.svg"
// const IconIndustry1 = "/images/icon-industry-1.svg"

const Container = styled.div`
  display: flex;
  height: 122px;
  width: 328px;
  grid-template-columns: 1fr;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
  padding: 1rem 0 1rem 1rem;
  cursor: pointer;
  @media screen and (max-width: 1079px) {
    width: 504px;
  }
  @media screen and (max-width: 599px) {
    width: 328px;
  }
`

const ContainerNoDetail = styled(Container)`
  background-color: rgba(255, 255, 255, 0.2);
  color: #ffffff;
  cursor: auto;
  h1 {
    color: #ffffff;
  }
  ul {
    color: #ffffff;
  }
`

const ContainerText = styled.div`
  margin-left: 20px;
  margin-top: 8px;
  color: #222222;
  h1 {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 8px;
  }
  ul {
    li {
      font-weight: 400;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 19px;
      margin-bottom: 0px;
    }
  }
`

const IconCircle = styled.div`
  width: 82px;
  height: 82px;
  border-radius: 41px;
  background-color: #e8f4ff;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CasestudyCategoryLink = props => {
  const data = props.data
  const hasDetail = data.hasDetail
  const iconType = data.iconType
  const desctiptionHtml = data.description.childMarkdownRemark.html
  let Icon = ""
  switch (iconType) {
    case 1:
      Icon = (
        // gatsby-plugin-react-svgを使うと、なぜかこのアイコンだけ表示できないので、pngで代用している
        <StaticImage
          src="../../images/icon-industry-1.png"
          style={{ width: 52, height: 52 }}
          placeholder="blurred"
          imgStyle={{ borderRadius: "100%", width: "100%", height: "100%" }}
          alt=""
        />
      )
      break
    case 2:
      Icon = <Icon2 style={{ width: 52, height: 52 }} />
      break
    case 3:
      Icon = <Icon3 style={{ width: 52, height: 52 }} />
      break
    case 4:
      Icon = <Icon4 style={{ width: 52, height: 52 }} />
      break
    case 5:
      Icon = <Icon5 style={{ width: 52, height: 52 }} />
      break
    case 6:
      Icon = <Icon6 style={{ width: 52, height: 52 }} />
      break
    default:
      // todo デフォルトアイコンが必要か検討
      Icon = <Icon2 style={{ width: 52, height: 52 }} />
      break
  }
  return (
    <>
      {hasDetail && (
        <Container className="link">
          <IconCircle>{Icon}</IconCircle>
          <ContainerText
            dangerouslySetInnerHTML={{ __html: desctiptionHtml }}
          />
        </Container>
      )}
      {!hasDetail && (
        <ContainerNoDetail className="link">
          <IconCircle>{Icon}</IconCircle>
          <ContainerText
            dangerouslySetInnerHTML={{ __html: desctiptionHtml }}
          />
        </ContainerNoDetail>
      )}
    </>
  )
}

// const CasestudyCategoryLink = ({ casearchive }) => {
//   const { Icon, CategoryName, SummaryList } = data.casestudyarchive
//   return (
//     <Container>
//       <IconBackGround>
//         <img>{Icon.file.url}</img>
//       </IconBackGround>
//       <ContainerText>
//         <CategoryName>{CategoryName}</CategoryName>
//         <Summary>
//           <SummaryList>{SummaryList}</SummaryList>
//           <SummaryList>{SummaryList}</SummaryList>
//         </Summary>
//       </ContainerText>
//     </Container>
//   )
// }

export default CasestudyCategoryLink
