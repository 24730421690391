import React from "react"
import styled from "@emotion/styled"
import CasestudyLink from "./casestudy-link"
import { StaticImage } from "gatsby-plugin-image"

const Title = styled.h1`
  color: #ffffff;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
  margin: 0px;
  margin-bottom: 40px;
`

const Root = styled.div`
  display: grid;
  grid-area: 1/1;
  position: relative;
  margin: 0 -1.0875rem;
  width: calc(100% + 2 * 1.0875rem);
  height: 607px;
  overflow: hidden;
  @media screen and (max-width: 1079px) {
    height: auto;
  }
`

const Container = styled.div`
  grid-area: 1/1;
  position: relative;
  padding: 40px 24px 113px 24px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1079px) {
    height: auto;
    padding-bottom: 40px;
  }
`

const Column = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .case {
    margin: 0px 12px 0 12px;
  }
  .case::last-child {
    margin: 0;
  }
  @media screen and (max-width: 1079px) {
    flex-direction: column;
    .case {
      margin: 0 0 24px 0;
    }
    .case:last-child {
      margin-bottom: 0;
    }
  }
`

const CasestudySection = props => {
  const developmentData = props.data

  const backGroundImageStyle = {
    gridArea: "1/1",
    width: "100%",
    height: "auto"
  }

  return (
    <Root>
      <StaticImage
        src="../../images/home-bg-02.png"
        style={backGroundImageStyle}
        alt=""
      />
      <Container>
        <Title>事例紹介</Title>
        <Column>
          {developmentData.map(data => {
            const dataNode = data.node
            const cardData = dataNode.developmentCaseCard
            const slug = dataNode.slug
            const tags = dataNode.metadata.tags
            const key = dataNode.contentful_id
            return (
              <div className="case">
                <CasestudyLink
                  cardData={cardData}
                  slug={slug}
                  tags={tags}
                  key={key}
                />
              </div>
            )
          })}
        </Column>
      </Container>
    </Root>
  )
}

// Contentful連携
// const CasestudySection = ({ data }) => {
//     const casestudy = data.casestudys
//     return (
//         <Root>
//             <Title>事例紹介</Title>
//             <Column>
//                 {casestudy.edges.map(edge =>
//                     <CasestudyLink key={edge.node.slug} casestudy={edge.node} />
//                 )}
//             </Column>
//         </Root>
//     )
// }

export default CasestudySection

// GraphQLのクエリ
// Todo：代表3件ピックしてくる
// export const query = graphql`
//     query {
//         topics: allContentfulPost(
//           limit: 3
//           filter: {publishedDate: {ne: "null"}}
//           sort: {order: DESC, fields: publishedDate}
//         ) {
//         edges {
//             node {
//             title
//             image {
//                 title
//                 file {
//                 url
//                 }
//             }
//             description {
//                 description
//             }
//             slug
//             updatedAt(locale: "ja-JP", formatString: "YYYY年MM月DD日")
//             }
//         }
//         }
//     }
//   `
