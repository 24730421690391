import React from "react"
import Seo from "../components/seo"
import KeyMessageSection from "../components/toppage/keymessage-section"
import StrengthSection from "../components/toppage/strength-section"
import CasestudySection from "../components/toppage/casestudy-section"
import CasearchiveSection from "../components/toppage/casearchive-section"
import ServicecategorySection from "../components/toppage/servicecategory-section"
import TopicsSection from "../components/toppage/topics-section"
import { graphql } from "gatsby"
import { UsePageContext } from "../context/page-context"
import { useState } from "react"

const TopPage = ({ data }) => {
  const { setIsAtContactPage, setIsAtNotfoundPage } = UsePageContext()
  useState(() => {
    setIsAtContactPage(false)
    setIsAtNotfoundPage(false)
  })
  const topVideoData = data.allContentfulTopVideo.nodes[0]
  const developmentCaseData = data.allContentfulDevelopmentCase.edges
  const topicData = data.allContentfulTopic.edges
  const servicecategoryData = data.allContentfulServiceCategory.edges
  const caseArchiveData = data.allContentfulCaseArchive.edges

  return (
    <>
      <Seo
        title="トップページ"
        // description="株式会社アクアのトップページです。"
      />
      <KeyMessageSection topVideoData={topVideoData} />
      <StrengthSection />
      <CasestudySection data={developmentCaseData} />
      <CasearchiveSection data={caseArchiveData} />
      <ServicecategorySection data={servicecategoryData} />
      <TopicsSection data={topicData} />
    </>
  )
}

export const query = graphql`
  query TopQuery {
    allContentfulTopVideo {
      nodes {
        updatedAt
        title
        description
        durationInSeconds
        video {
          assetId
          playbackId
          ready
          uploadId
        }
      }
    }
    allContentfulDevelopmentCase(
      sort: { fields: displayOrder, order: ASC }
      filter: { isRepresentative: { eq: true } }
      limit: 3
    ) {
      edges {
        node {
          displayOrder
          contentful_id
          slug
          developmentCaseCard {
            title
            companyName
            description
            image {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          metadata {
            tags {
              id
              contentful_id
              name
            }
          }
        }
      }
    }
    allContentfulCaseArchive(sort: { fields: displayOrder, order: ASC }) {
      edges {
        node {
          contentful_id
          iconType
          hasDetail
          section
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulServiceCategory(sort: { order: ASC, fields: displayOrder }) {
      edges {
        node {
          contentful_id
          title
          description {
            description
          }
          displayOrder
          icon {
            gatsbyImageData(placeholder: TRACED_SVG)
          }
          iconType
          hasDetail
        }
      }
    }
    allContentfulTopic(sort: { fields: createdAt, order: DESC }, limit: 3) {
      edges {
        node {
          title
          slug
          createdAt(locale: "ja-JP", formatString: "YYYY/MM/DD")
          topimage {
            gatsbyImageData(placeholder: BLURRED, height: 219)
          }
          contentful_id
          category {
            name
            contentful_id
            image {
              gatsbyImageData(placeholder: BLURRED, height: 219)
            }
          }
        }
      }
    }
  }
`

export default TopPage
