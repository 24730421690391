import React, { useEffect, useRef, useState } from "react"
import videojs from "video.js"
import PlayIcon from "@material-ui/icons/PlayCircleFilled"
import PauseIcon from "@material-ui/icons/PauseCircleFilled"
import FullscreenIcon from "@material-ui/icons/Fullscreen"
import CustomSlider from "./custom-slider"
import PosterImage from "./poster-image"
import styled from "@emotion/styled"

const ControlButton = styled.button`
  border: none;
  background: none;
`

const PlayButton = styled(ControlButton)`
  position: absolute;
  top: calc(50% - 67px / 2);
  left: calc(50% - 67px / 2);
  width: 67px;
  height: 67px;
`

const FullScreenButton = styled(ControlButton)`
  width: 44px;
  height: 44px;
`

const ControlDiv = styled.div`
  height: calc(100% - 8px);
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const Movie = props => {
  const videoData = props.videoData
  const playbackId = videoData.video.playbackId
  const source = `https://stream.mux.com/${playbackId}.m3u8`
  const durationInSeconds = videoData.durationInSeconds

  const options = {
    liveui: true,
    controls: false,
    responsive: true,
    poster: false,
    textTrackDisplay: false,
    loadingSpinner: false,
    bigPlayButton: false,
    controlBar: false,
    errorDisplay: false,
    textTrackSettings: false,
    fill: true,
    autoplay: false,
    preload: false,
    sources: [
      {
        src: source,
        type: "application/x-mpegURL",
      },
    ],
  }

  const videoRef = useRef(null)
  const playerRef = useRef(null)
  const [player, setPlayer] = useState(null)
  const [isPosterShown, setIsPosterShown] = useState(true)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  const [playbackPosition, setPlaybackPosition] = useState(0)

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = videoRef.current
      playerRef.current = videojs(videoElement, options)
      const player = videojs.getPlayer("player")
      setPlayer(player)
      player.duration(durationInSeconds)

      player.on(["waiting", "pause"], () => {
        if(isPlaying){
          setIsPlaying(false)
        }
      })

      player.on("playing", () => {
        if(!isPlaying){
          setIsPlaying(true)
        }
      })
    }
  }, [playerRef.current])

  const play = () => {
    if (playerRef.current) {
      setIsPosterShown(false)
      if (!isPlaying) {
        setIsPlaying(true)
        player.play()
      }
      if (isPlaying) {
        setIsPlaying(false)
        player.pause()
        console.log("pause")
      }
    }
  }

  const showFullscreen = () => {
    const isFullscreen = player.isFullscreen()
    if (!isFullscreen) {
      player.requestFullscreen()
    }
  }

  const handleSeekbarChange = (event, newValue) => {
    const isTrusted = event.isTrusted
    if (isTrusted) {
      setPlaybackPosition(newValue)
      const newValueInSeconds = newValue * 0.01 * durationInSeconds
      player.currentTime(newValueInSeconds)
    }
  }

  return (
    <>
      {/* video.jsのposterがモバイル端末で表示できない? */}
      <div style={{ display: isPosterShown ? "block" : "none" }}>
        <PosterImage />
        <PlayButton onClick={() => play()} name="動画を再生" aria-label="play">
          <StyledPlayIcon />
        </PlayButton>
      </div>
      {/* ここではstyled-componentsを使用できない？ */}
      <div style={{ display: isPosterShown ? "none" : "block" }}>
        <div
          data-vjs-player
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          onTouchStart={() => setIsHovering(!isHovering)}
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
          }}
          role="player"
        >
          <video-js
            ref={videoRef}
            // className="vjs-default-skin"
            id="player"
            style={{ width: "100%", height: "100%", cursor: "pointer" }}
            onClick={() => play()}
          />
          <PlayButton
            onClick={() => play()}
            name="動画を再生"
            aria-label="play or pause"
          >
            {isPlaying && isHovering && <StyledPauseIcon />}
            {!isPlaying && <StyledPlayIcon />}
          </PlayButton>
          <div
            className="control"
            style={{
              width: "100%",
              height: 44,
              position: "absolute",
              bottom: 0,
              left: 0,
              display: isHovering ? "flex" : "none",
              flexDirection: "column",
            }}
          >
            <CustomSlider
              onChange={handleSeekbarChange}
              value={playbackPosition}
              defaultValue={0}
            />
            <ControlDiv className="buttons">
              <FullScreenButton
                onClick={() => showFullscreen()}
                name="動画をフルスクリーンで表示"
              >
                <StyledFullScreenIcon />
              </FullScreenButton>
            </ControlDiv>
          </div>
        </div>
      </div>
    </>
  )
}

const StyledPlayIcon = () => {
  return (
    <PlayIcon
      style={{
        width: "100%",
        height: "100%",
        fill: "#ffffff",
        opacity: 0.85,
      }}
    />
  )
}

const StyledPauseIcon = () => {
  return (
    <PauseIcon
      style={{
        width: "100%",
        height: "100%",
        fill: "#ffffff",
        opacity: 0.85,
      }}
    />
  )
}

const StyledFullScreenIcon = () => {
  return (
    <FullscreenIcon
      style={{
        width: "100%",
        height: "100%",
        fill: "#ffffff",
        opacity: 0.85,
      }}
    />
  )
}

export default Movie
