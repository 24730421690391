import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"
import DynamicImage from "../dynamic-image"
import { StaticImage } from "gatsby-plugin-image"

const Root = styled.div`
  margin: 0 -1.0875rem;
  display: grid;
  width: calc(100% + 2 * 1.0875rem);
  height: 607px;
  position: relative;
  grid-area: 1/1;
  overflow: hidden;
  @media screen and (max-width: 1079px) {
    height: auto;
  }
`

const Container = styled.div`
  /* max-width: calc(372px + 50px + 497px); */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: 1/1;
  position: relative;
  /* padding: 94px 0px 98px 0px; */
  .image {
    margin-right: 50px;
  }
  @media screen and (max-width: 1079px) {
    height: auto;
    flex-direction: column-reverse;
    align-items: center;
    padding: 48px 24px 121px 24px;
    .image {
      margin: 0;
    }
    .description {
      margin-bottom: 24px;
    }
  }
`

const Column = styled.div`
  width: 497px;
  display: flex;
  flex-direction: column;
  align-items: start;
  @media screen and (max-width: 1079px) {
    align-items: center;
  }
  @media screen and (max-width: 712px) {
    width: 100%;
    /* align-items: center; */
    /* padding: 0 24px 0 24px; */
  }
`

const Title = styled.h1`
  color: #ffffff;
  font-size: 48px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 72px;
  margin-bottom: 24px;
  @media screen and (max-width: 712px) {
    font-size: 30px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 45px;
    text-align: center;
  }
`

const Text = styled.h2`
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 24px;
  @media screen and (max-width: 712px) {
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
  }
`

const StyledLink = styled(props => <Link {...props} />)`
  width: 123px;
  height: 44px;
  border: none;
  border-radius: 22.5px;
  background-color: #ffffff;
  color: #222222;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.35px;
    text-align: center;
    margin-bottom: 0;
    vertical-align: middle;
  }
  &:hover {
    background-color: #bce9ff;
  }
  &:active {
    background-color: #a7d4eb;
  }
`

const ImageContainer = styled.div`
  max-width: 372px;
  display: flex;
  justify-content: center;
`

// 画像をContentful入稿後、StaticImageではなくDynamicImageコンポーネントを使って表示し直す
const StrengthSection = props => {
  const data = useStaticQuery(graphql`
    query ImageQuery {
      contentfulAsset(title: { eq: "aqua-develop" }) {
        gatsbyImageData(
          breakpoints: 799
          placeholder: NONE
          width: 372
          layout: CONSTRAINED
        )
        title
      }
    }
  `)

  const backGroundImageStyle = {
    gridArea: "1/1",
    width: "100%",
    height: "auto",
    // height: 607,
  }

  return (
    <Root>
      <StaticImage
        src="../../images/home-bg-01.png"
        style={backGroundImageStyle}
        alt=""
      />
      <Container>
        <ImageContainer className="image">
          <DynamicImage image={data.contentfulAsset.gatsbyImageData} />
        </ImageContainer>
        <Column className="description">
          <Title>変化に強い、高速開発</Title>
          <Text>
            お客様との対話によるアイデア創出から、高速開発ツール
            GeneXus・クラウド環境 Microsoft Azure を組み合わせ、
            ビジネスの変化を加速し進化させるITシステムを構築します。
          </Text>
          <StyledLink to="/strength/">
            <p>詳しく見る</p>
          </StyledLink>
        </Column>
      </Container>
    </Root>
  )
}

export default StrengthSection
