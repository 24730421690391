import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import TopicsLink from "./topics-link"

const Container = styled.div`
  background-color: #ffffff;
  padding: 40px 24px calc(45px - 1.45rem) 24px;
  @media screen and (max-width: 1079px) {
    height: auto;
    padding-bottom: 40px;
  }
  margin: 0 -1.0875rem;
`

const Title = styled.h1`
  color: #222222;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
  margin-bottom: 40px;
`

const Column = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  .card {
    margin-right: 24px;
  }
  .card:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 1079px) {
    flex-direction: column;
    .card {
      margin: 0 0 24px 0;
    }
    .card:last-child {
      margin-bottom: 0;
    }
  }
`

const StyledLink = styled(props => <Link {...props} />)`
  color: inherit;
  text-decoration: none;
`

const StyledButton = styled.button`
  padding: 0 1rem 0 1rem;
  height: 44px;
  border: none;
  border-radius: 22.5px;
  background-color: #efefef;
  /* box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15); */
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.35px;
  line-height: 44px;
  text-align: center;
  :hover {
    background-color: #bce9ff;
  }
  :active {
    background-color: #a7d4eb;
  }
`

const TopicsSection = props => {
  const topicsData = props.data
  return (
    <Container>
      <Title>トピックス</Title>
      <Column>
        {topicsData.map(data => {
          const dataNode = data.node
          const key = dataNode.contentful_id
          return (
            <div className="card" key={key}>
              <TopicsLink data={dataNode} />
            </div>
          )
        })}
      </Column>
      <Column>
        <StyledLink to="/topics/">
          <StyledButton>全てのトピックスを見る</StyledButton>
        </StyledLink>
      </Column>
    </Container>
  )
}


export default TopicsSection