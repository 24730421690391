import React from "react"
import styled from "@emotion/styled"
import CasestudyCategoryLink from "./casestudy-category-link"
import { StaticImage } from "gatsby-plugin-image"

const Root = styled.div`
  /* background-color: #424EAF; */
  display: grid;
  grid-area: 1/1;
  position: relative;
  margin: 0 -1.0875rem;
  width: calc(100% + 2 * 1.0875rem);
  height: 607px;
  overflow: hidden;
  @media screen and (max-width: 1079px) {
    height: auto;
  }
`

const Container = styled.div`
  padding: 40px 24px 118px 24px;
  grid-area: 1/1;
  position: relative;
  display: flex;
  flex-direction: column;
  .subsection {
    margin-bottom: 64px;
  }
  .subsection:last-child {
    margin-bottom: 0;
  }
  @media screen and (max-width: 1079px) {
    padding: 40px 24px 40px 24px;
    .subsection {
      margin-bottom: 40px;
    }
  }
`

const SubSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* background-color: #424eaf; */
  margin: 0 -1.0875rem;
`

const Title = styled.h1`
  color: #ffffff;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
  padding-bottom: 40px;
  margin: 0px;
`

const Links = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  .link {
    margin-right: 24px;
  }
  .link:last-child {
    margin-right: 0;
  }
  @media screen and (max-width: 1079px) {
    flex-direction: column;
    .link {
      margin-right: 0;
      margin-bottom: 20px;
    }
    .link:last-child {
      margin-bottom: 0px;
    }
  }
  /* margin: 40px 24px 40px 24px; */
`

const CasearchiveSection = props => {
  const caseArchives = props.data
  const achievementArchives = caseArchives.filter(archive => {
    const node = archive.node
    const section = node.section
    if (section === "実績のある業種") {
      return archive
    }
    return
  })
  const objectiveArchives = caseArchives.filter(archive => {
    const node = archive.node
    const section = node.section
    if (section === "開発目的別") {
      return archive
    }
    return
  })

  const backGroundImageStyle = {
    gridArea: "1/1",
    width: "100%",
    height: "auto"
  }

  return (
    <Root>
      <StaticImage
        src="../../images/home-bg-03.png"
        style={backGroundImageStyle}
        alt=""
      />
      <Container>
        <SubSection className="subsection">
          <Title>実績のある業種</Title>
          <Links>
            {achievementArchives.map(archive => {
              const node = archive.node
              const key = node.contentful_id
              return <CasestudyCategoryLink data={node} key={key} />
            })}
          </Links>
        </SubSection>
        <SubSection className="subsection">
          <Title>開発目的別</Title>
          <Links>
            {objectiveArchives.map(archive => {
              const node = archive.node
              const key = node.contentful_id
              return <CasestudyCategoryLink data={node} key={key} />
            })}
          </Links>
        </SubSection>
      </Container>
    </Root>
  )
}

export default CasearchiveSection
