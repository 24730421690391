import React from "react";
import { StaticImage } from "gatsby-plugin-image"

const PosterImage = () => {
    return (
        <StaticImage
            src="../../images/top-zhang-revised.jpeg"
            placeholder="none"
            alt="動画サムネイル"
            style={{
                width: "100%",
                height: "100%",
                position: "relative",
            }}
        />
    )
}

export default PosterImage