import React from "react"
import Slider from "@material-ui/core/Slider"
import PropTypes from "prop-types"
import { ThemeProvider, createTheme } from "@material-ui/core/styles"

const CustomSlider = props => {
  const theme = createTheme({
    overrides: {
      MuiSlider: {
        root: {
          padding: "3px 0",
          "@media (pointer: coarse)": {
            padding: "3px 0"
          }
        },
        rail: {
          height: 3,
          backgroundColor: "#a0e5e3",
          opacity: 0.8,
        },
        track: {
          height: 3,
          color: "#243b88"
        },
        thumb: {
          color: "#243b88"
        },
      },
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <Slider
        value={props.value}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
      />
    </ThemeProvider>
  )
}

CustomSlider.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  defaultValue: PropTypes.number.isRequired,
}

export default CustomSlider
