import React from "react"
import styled from "@emotion/styled"
import ServicecategoryCard from "./servicecategory-card"
import { StaticImage } from "gatsby-plugin-image"

const Root = styled.div`
  /* padding: 40px 24px 85px 24px; */
  /* background-color: #182c71; */
  margin: 0 -1.0875rem;
  display: grid;
  width: calc(100% + 2 * 1.0875rem);
  height: 607px;
  position: relative;
  grid-area: 1/1;
  overflow: hidden;
  // 調整 (ブレイクポイントを1079pxとすると、1080px近傍でレイアウトが崩れる)
  @media screen and (max-width: 1080px) {
    height: auto;
  }
`

const Container = styled.div`
  grid-area: 1/1;
  position: relative;
  /* padding: 40px 24px 85px 24px; */
  padding: 40px 24px 40px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1079px) {
    padding: 40px 24px 40px 24px;
  }
`

const Title = styled.h1`
  color: #ffffff;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
  margin-bottom: 40px;
`

const CardContainer = styled.div`
  /* width: 1032px; */
  /* margin: auto; */
  display: flex;
  /* flex-direction: row; */
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 1559px;
  .card {
    margin: 0 24px 24px 0;
  }
  .card:nth-child(even) {
    margin-right: 0;
  }
  .card:last-child {
    /* margin-bottom: 0; */
  }
  // 調整 (ブレイクポイントを1079pxとすると、1080px近傍でレイアウトが崩れる)
  @media screen and (max-width: 1080px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    .card {
      margin-right: 0;
    }
  }
`
const ServicecategorySection = props => {
  const categoryNodes = props.data
  
  const backGroundImageStyle = {
    gridArea: "1/1",
    width: "100%",
    height: "auto",
  }

  return (
    <Root>
      <StaticImage
        src="../../images/home-bg-04.png"
        style={backGroundImageStyle}
        alt=""
      />
      <Container>
        <Title>サービスカテゴリー</Title>
        <CardContainer>
          {categoryNodes.map(categoryNode => {
            const category = categoryNode.node
            const key = category.contentful_id
            const iconType = category.iconType
            const title = category.title
            const description = category.description.description
            const hasDetail = category.hasDetail
            return (
              <ServicecategoryCard
                title={title}
                description={description}
                iconType={iconType}
                key={key}
                hasDetail={hasDetail}
              />
            )
          })}
        </CardContainer>
      </Container>
    </Root>
  )
}

export default ServicecategorySection
