import React from "react"
import styled from "@emotion/styled"
import {keyframes } from '@emotion/react'
import ChartBack from "../../images/svg/top-chat-2.svg"
import ChartFront from "../../images/svg/top-chat-1.svg"
import Movie from "./movie"

// animation
const showMessage  = keyframes`
  from {
      transform: translateX(-200px);
    }
`

const showBack  = keyframes`
  0% {
    transform: translateX(-200px) rotate(-30deg);
    opacity:0;
  }
  39% {
    transform: translateX(-200px) rotate(-30deg);
    opacity:0;
  }
  40% {
    transform: translateX(-200px) rotate(-30deg);
    opacity:1;
  }
  100% {
    transform: translateX(0px) rotate(0deg);
    opacity: 1;
  }
`
const showFront  = keyframes`
  0% {
    transform: translateX(200px) rotate(30deg);
    opacity:0;
  }
  62% {
    transform: translateX(200px) rotate(30deg);
    opacity:0;
  }
  63% {
    transform: translateX(200px) rotate(30deg);
    opacity:1;
  }
  100% {
    transform: translateX(0px) rotate(0deg);
    opacity: 1;
  }
`

const showVideo  = keyframes`
  0% {
    transform: scale(0.8);
    opacity:0;
  }
  88% {
    transform: scale(0.8);
    opacity:0;
  }
  89% {
    transform: scale(0.8);
    opacity:1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`

// css
const Container = styled.div`
  height: 543px;
  padding: 29px 11px 20px 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1079px) {
    height:auto;
    align-items: flex-start;
  }
  @media screen and (max-width: 712px) {
    flex-direction: column;
    align-items: center;
    height: auto;
    padding: 0;
  }
`

const Column = styled.div`
  position: relative;
  z-index: 10;
  height: 100%;
  padding-top: 124px;
  display: flex;
  flex-direction: column;
  animation: ${showMessage} 600ms ease;
  @media screen and (max-width: 1079px) {
    padding-top: 70px;
  }
  @media screen and (max-width: 712px) {
    margin-bottom: 50px;
    padding-top: 40px;
  }
`

const Message = styled.div`
  position: relative;
  z-index: 10;
  font-size: 44px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 65px;
  color: #163087;
  @media screen and (max-width: 1079px) {
    font-size: 32px;
    padding-left: 13px;
    padding-right: 12px;
    letter-spacing: 0;
    line-height: 47px;
  }
  @media screen and (max-width: 346px) {
    font-size: 25px;
  }
`

const RightColumn = styled.div`
  width: 607px;
  height: 494px;
  margin-left: 10px;
  position: relative;
  @media screen and (max-width: 1079px) {
    width: 343px;
    height: 300px;
    margin-left: 0px;
    /* width: 100%;
    margin: 0; */
  }
  @media screen and (max-width: 712px) {
    height: 350px;
  }
`
const ChartBackContainer = styled.div`
  height: 494px;
  width: 584px;
  position: absolute;
  top: 0;
  left: 0;
  animation: ${showBack} 1000ms ease;
  @media screen and (max-width: 1079px) {
    height: 280px;
    width: 331px;
    /* width: 70%;
    height: 70%; */
  }
`
const ChartFrontContainer = styled.div`
  height: 494px;
  width: 566px;
  position: absolute;
  top: 0;
  left: 41px;
  animation: ${showFront} 1600ms ease;
  @media screen and (max-width: 1079px) {
    height: 280px;
    width: calc(100% - 23px);
    left: 23px;
  }
`

const MovieContainer = styled.div`
  height: 261px;
  width: 464px;
  position: absolute;
  top: 94px;
  left: 89px;
  animation: ${showVideo} 1800ms ease;
  @media screen and (max-width: 1079px) {
    height: 147.24px;
    width: 261.76px;
    border-radius: 4px;
    top: 53.19px;
    left: 50.15px;
  }
`

const Title = styled.div`
  position: absolute;
  top: 366px;
  left: 90px;
  color: #FFFFFF;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  text-align: left;
  animation: ${showVideo} 1800ms ease;
  @media screen and (max-width: 1079px) {
    font-size: 12px;
    top: 205px;
    left: 51.15px;
  }
`

// todo: 社長動画を追加
const KeyMessageSection = (props) => {
  const videoData = props.topVideoData
  return (
    <Container>
      <Column>
        <Message>対話からはじめる、</Message>
        <Message>コアコンピタンスを</Message>
        <Message>進化させるIT。</Message>
      </Column>

      <RightColumn>
        {/********************** svg **********************/}
        <ChartBackContainer>
          <ChartBack style={{ height: '100%', width: "100%", filter: "drop-shadow(0 10px 30px rgba(0,0,0,0.15))"}}/>
        </ChartBackContainer>
        <ChartFrontContainer>
          <ChartFront style={{ height: '100%', width: "100%", filter: "drop-shadow(0 10px 30px rgba(0,0,0,0.3))" }}/>
        </ChartFrontContainer>
       
        <MovieContainer>
          <Movie videoData={videoData} />
          {/* <iframe 
            width="99.9%" 
            height="100%" 
            src="https://www.youtube.com/embed/WWn64O7vysE" 
            title="株式会社アクア 代表取締役" 
            name="株式会社アクア 代表取締役 張からのメッセージ" 
            frameBorder="0" 
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen
            style={{ marginBottom: '0'}}
            loading="lazy" 
          /> */}
        </MovieContainer>
        <Title>株式会社アクア 代表取締役　張 宏斌</Title>
      </RightColumn>  
    </Container>
  )
}

export default KeyMessageSection
