import React from "react"
// import { Link } from "gatsby"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import Icon1 from "../../images/svg/icon-service-1.svg"
import Icon2 from "../../images/svg/icon-service-2.svg"
import Icon3 from "../../images/svg/icon-service-3.svg"
import Icon4 from "../../images/svg/icon-service-4.svg"
import Icon5 from "../../images/svg/icon-service-5.svg"
import Icon6 from "../../images/svg/icon-service-6.svg"

const Card = styled.div`
  height: 122px;
  width: 504px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  padding: 24px 20px 24px 24px;
  cursor: pointer;
  /* padding: 20px 10px 24px 20px; */
  /* cursor: pointer; */
  @media screen and (max-width: 599px) {
    width: 327px;
    height: auto;
    max-height: 186px;
    padding: 24px 20px 24px 20px;
  }
`

const CardNoDetail = styled(Card)`
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.2);
  cursor: auto;
`

const Column = styled.div`
  width: calc(100% - 82px - 20px);
  margin-left: 20px;
  /* padding: 4px 0 4px 0; */
  display: flex;
  flex-direction: column;
  justify-content: start;
`

const IconCircle = styled.div`
  width: 82px;
  height: 82px;
  border-radius: 41px;
  background-color: #e8f4ff;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 799px) {
    border-radius: 0;
  }
`

const Title = styled.h1`
  font-size: 19px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
  margin-bottom: 10px;
  padding-right: 12px;
`

const Description = styled.h2`
  font-size: 13px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 19px;
  margin: 0;
  text-align: justify;
`

const ServicecategoryCard = props => {
  const hasDetail = props.hasDetail
  let Icon = ""
  switch (props.iconType) {
    case 1:
      Icon = <Icon1 style={{ width: 51, height: 52 }} />
      break
    case 2:
      Icon = <Icon2 style={{ width: 51, height: 52 }} />
      break
    case 3:
      Icon = <Icon3 style={{ width: 51, height: 52 }} />
      break
    case 4:
      Icon = <Icon4 style={{ width: 51, height: 52 }} />
      break
    case 5:
      Icon = <Icon5 style={{ width: 51, height: 52 }} />
      break
    case 6:
      Icon = <Icon6 style={{ width: 51, height: 52 }} />
      break
    default:
      // todo デフォルトアイコンが必要か検討
      Icon = <Icon2 style={{ width: 51, height: 52 }} />
      break
  }
  return (
    <>
      {hasDetail && (
        <Card className="card">
          <IconCircle>{Icon}</IconCircle>
          <Column>
            <Title>{props.title}</Title>
            <Description>{props.description}</Description>
          </Column>
        </Card>
      )}
      {!hasDetail && (
        <CardNoDetail className="card">
          <IconCircle>{Icon}</IconCircle>
          <Column>
            <Title>{props.title}</Title>
            <Description>{props.description}</Description>
          </Column>
        </CardNoDetail>
      )}
    </>
  )
}

ServicecategoryCard.propTypes = {
  iconType: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  hasDetail: PropTypes.bool.isRequired,
}

export default ServicecategoryCard
